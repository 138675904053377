<template>
  <b-container class="container" :style="'gap: 25px; max-width: 100%; min-height: 100%; padding-bottom:20px'">
    <div @click="previousStep">
      <svg viewBox="0 0 24 24" fill="none" class="icon-back" width="20" heigth="40" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10.5303 5.46967C10.8232 5.76256 10.8232 6.23744 10.5303 6.53033L5.81066 11.25H20C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75H5.81066L10.5303 17.4697C10.8232 17.7626 10.8232 18.2374 10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303L3.46967 12.5303C3.17678 12.2374 3.17678 11.7626 3.46967 11.4697L9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967Z"
            fill="#1C274C"></path>
        </g>
      </svg>
    </div>
    <transition name="slide">
      <div v-if="isVisible">
        <div class="text-header">Escolha o seu plano ideal!</div>

        <div class="btn-div d-flex justify-content-around align-items-center">
          <div class="button-container">
            <button type="button" :class="{
              'btn-checkd': selected === config.ID_TIPO_ASSINATURA_MENSAL,
              'btn-noCheck': selected !== config.ID_TIPO_ASSINATURA_MENSAL,
            }" @click="selectOption(config.ID_TIPO_ASSINATURA_MENSAL)">
              Mensal
            </button>
            <span :class="selected === config.ID_TIPO_ASSINATURA_MENSAL ? 'chip-top' : 'chip-top-enabled'">
              15% Off
            </span>
          </div>
          <div class="button-container">
            <button type="button" :class="{
              'btn-checkd': selected === config.ID_TIPO_ASSINATURA_ANUAL,
              'btn-noCheck': selected !== config.ID_TIPO_ASSINATURA_ANUAL,
            }" @click="selectOption(config.ID_TIPO_ASSINATURA_ANUAL)">
              Anual
            </button>
            <span :class="selected === config.ID_TIPO_ASSINATURA_ANUAL ? 'chip-top' : 'chip-top-enabled'">
              25% Off
            </span>
          </div>
        </div>
      </div>
    </transition>

    <div class="swiper-button-prev" @click="prevSlide">
      <svg width="10" height="19" class="mr-4" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.38685 12.1612L7.50685 18.2812C7.75667 18.5296 8.0946 18.6689 8.44685 18.6689C8.7991 18.6689 9.13704 18.5296 9.38685 18.2812C9.51182 18.1573 9.61102 18.0098 9.67871 17.8473C9.7464 17.6848 9.78125 17.5106 9.78125 17.3346C9.78125 17.1585 9.7464 16.9843 9.67871 16.8218C9.61102 16.6593 9.51182 16.5118 9.38685 16.3879L3.25352 10.2812C3.12855 10.1573 3.02935 10.0098 2.96166 9.84732C2.89397 9.68484 2.85912 9.51057 2.85912 9.33455C2.85912 9.15854 2.89397 8.98426 2.96166 8.82179C3.02935 8.65931 3.12855 8.51184 3.25352 8.38789L9.38685 2.28122C9.63793 2.03192 9.77968 1.69309 9.78093 1.33927C9.78218 0.985451 9.64282 0.645626 9.39352 0.394554C9.14422 0.143484 8.80539 0.00172988 8.45157 0.000478628C8.09775 -0.000770716 7.75792 0.138586 7.50685 0.38789L1.38685 6.50789C0.637782 7.25789 0.217036 8.27455 0.217036 9.33455C0.217036 10.3946 0.637782 11.4112 1.38685 12.1612Z"
          fill="white" />
      </svg>
    </div>

    <div class="swiper-button-next" @click="nextSlide">
      <svg width="10" height="19" class="ml-4" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.61315 6.50772L2.49315 0.387723C2.24333 0.139389 1.9054 0 1.55315 0C1.2009 0 0.862964 0.139389 0.613148 0.387723C0.488177 0.511674 0.388984 0.659142 0.321293 0.821621C0.253601 0.9841 0.21875 1.15837 0.21875 1.33439C0.21875 1.51041 0.253601 1.68468 0.321293 1.84716C0.388984 2.00964 0.488177 2.15711 0.613148 2.28106L6.74648 8.38772C6.87145 8.51167 6.97065 8.65914 7.03834 8.82162C7.10603 8.9841 7.14088 9.15837 7.14088 9.33439C7.14088 9.51041 7.10603 9.68468 7.03834 9.84716C6.97065 10.0096 6.87145 10.1571 6.74648 10.2811L0.613148 16.3877C0.362076 16.637 0.220323 16.9759 0.219073 17.3297C0.217823 17.6835 0.357179 18.0233 0.606482 18.2744C0.855786 18.5255 1.19462 18.6672 1.54843 18.6685C1.90225 18.6697 2.24208 18.5304 2.49315 18.2811L8.61315 12.1611C9.36222 11.4111 9.78296 10.3944 9.78296 9.33439C9.78296 8.27439 9.36222 7.25773 8.61315 6.50772Z"
          fill="white" />
      </svg>
    </div>
    <div class="lottie" ref="lottieContainer"></div>

    <swiper ref="mySwiper" :slides-per-view="1" :space-between="30" :loop="true" :effect="'cards'" @swiper="onSwiper"
      @slideChange="onSlideChange">
      <swiper-slide v-for="(n, index) in priceOptions" :key="index" class="test" :class="{ test_2: true }">
        <div :class="['card-container', { 'card-activated': n.id === planeSelect }]">
          <div class="card-title">
            {{ n.Title }}
            <div v-if="n.sale" :class="n.id === planeSelect ? 'chip-selected' : 'chip'">
              {{ n.saleValue }}
            </div>
          </div>
          <ul :v-if="n.benefits" v-for="(benefit, index) in n.benefits" :key="index" class="card-list">
            <li :class="[
              'card-list-item',
              { 'line-through': benefit && benefit.checked },
              { 'line-through-selected': n.id === planeSelect && benefit && benefit.checked },
            ]">
              <svg v-if="benefit && benefit.checked === true" width="18" height="18" class="mr-2" viewBox="0 0 24 24"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path :style="{ fill: n.id === planeSelect ? '#8ec77f' : '#b01111' }"
                    d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z">
                  </path>
                </g>
              </svg>

              <svg v-else width="18" height="18" class="mr-2" viewBox="0 0 25 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3749_4704)">
                  <path :style="{ fill: n.id === planeSelect ? '#a5e793' : '#4FA935' }"
                    d="M22.8186 4.43101L8.99963 18.249C8.90672 18.3423 8.79631 18.4163 8.67473 18.4668C8.55315 18.5173 8.42279 18.5433 8.29113 18.5433C8.15948 18.5433 8.02912 18.5173 7.90754 18.4668C7.78596 18.4163 7.67554 18.3423 7.58263 18.249L2.23863 12.9C2.14572 12.8067 2.03531 12.7327 1.91373 12.6822C1.79214 12.6317 1.66179 12.6057 1.53013 12.6057C1.39848 12.6057 1.26812 12.6317 1.14654 12.6822C1.02496 12.7327 0.914542 12.8067 0.821633 12.9C0.728356 12.9929 0.654344 13.1033 0.603842 13.2249C0.55334 13.3465 0.527344 13.4769 0.527344 13.6085C0.527344 13.7402 0.55334 13.8705 0.603842 13.9921C0.654344 14.1137 0.728356 14.2241 0.821633 14.317L6.16763 19.662C6.73158 20.2249 7.49583 20.5411 8.29263 20.5411C9.08944 20.5411 9.85369 20.2249 10.4176 19.662L24.2356 5.84701C24.3288 5.75412 24.4026 5.64377 24.4531 5.52228C24.5035 5.40079 24.5294 5.27054 24.5294 5.13901C24.5294 5.00747 24.5035 4.87723 24.4531 4.75574C24.4026 4.63425 24.3288 4.5239 24.2356 4.43101C24.1427 4.33773 24.0323 4.26372 23.9107 4.21322C23.7891 4.16272 23.6588 4.13672 23.5271 4.13672C23.3955 4.13672 23.2651 4.16272 23.1435 4.21322C23.022 4.26372 22.9115 4.33773 22.8186 4.43101Z"
                    fill="#4FA935" />
                </g>
                <defs>
                  <clipPath id="clip0_3749_4704">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                  </clipPath>
                </defs>
              </svg>

              {{ benefit.name }}
            </li>
          </ul>
          <div @click="openzada(n.extraBenefits, n.Title)" class="card-benefits">
            <div :class="n.id === planeSelect ? 'flex-row-activated' : 'flex-row'">
              <svg width="25" height="24" class="mr-2" viewBox="0 0 25 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3750_5120)">
                  <path :style="{ fill: n.id === planeSelect ? '#a5e793' : '#4FA935' }"
                    d="M20.5 7H18.762C19.3274 6.5016 19.7762 5.88488 20.0766 5.1936C20.3769 4.50232 20.5215 3.75341 20.5 3C20.5 2.73478 20.3946 2.48043 20.2071 2.29289C20.0196 2.10536 19.7652 2 19.5 2C19.2348 2 18.9804 2.10536 18.7929 2.29289C18.6054 2.48043 18.5 2.73478 18.5 3C18.5 5.622 16.129 6.53 14.326 6.841C14.9915 5.66105 15.3921 4.35037 15.5 3C15.5 2.20435 15.1839 1.44129 14.6213 0.87868C14.0587 0.31607 13.2956 0 12.5 0C11.7044 0 10.9413 0.31607 10.3787 0.87868C9.81607 1.44129 9.5 2.20435 9.5 3C9.60791 4.35037 10.0085 5.66105 10.674 6.841C8.871 6.53 6.5 5.622 6.5 3C6.5 2.73478 6.39464 2.48043 6.20711 2.29289C6.01957 2.10536 5.76522 2 5.5 2C5.23478 2 4.98043 2.10536 4.79289 2.29289C4.60536 2.48043 4.5 2.73478 4.5 3C4.47851 3.75341 4.62307 4.50232 4.92344 5.1936C5.2238 5.88488 5.6726 6.5016 6.238 7H4.5C3.43913 7 2.42172 7.42143 1.67157 8.17157C0.921427 8.92172 0.5 9.93913 0.5 11L0.5 12C0.5 12.5304 0.710714 13.0391 1.08579 13.4142C1.46086 13.7893 1.96957 14 2.5 14V19C2.50159 20.3256 3.02888 21.5964 3.96622 22.5338C4.90356 23.4711 6.17441 23.9984 7.5 24H17.5C18.8256 23.9984 20.0964 23.4711 21.0338 22.5338C21.9711 21.5964 22.4984 20.3256 22.5 19V14C23.0304 14 23.5391 13.7893 23.9142 13.4142C24.2893 13.0391 24.5 12.5304 24.5 12V11C24.5 9.93913 24.0786 8.92172 23.3284 8.17157C22.5783 7.42143 21.5609 7 20.5 7ZM12.5 2C12.7652 2 13.0196 2.10536 13.2071 2.29289C13.3946 2.48043 13.5 2.73478 13.5 3C13.3793 4.06283 13.0387 5.08891 12.5 6.013C11.9613 5.08891 11.6207 4.06283 11.5 3C11.5 2.73478 11.6054 2.48043 11.7929 2.29289C11.9804 2.10536 12.2348 2 12.5 2ZM2.5 11C2.5 10.4696 2.71071 9.96086 3.08579 9.58579C3.46086 9.21071 3.96957 9 4.5 9H11.5V12H2.5V11ZM4.5 19V14H11.5V22H7.5C6.70435 22 5.94129 21.6839 5.37868 21.1213C4.81607 20.5587 4.5 19.7956 4.5 19ZM20.5 19C20.5 19.7956 20.1839 20.5587 19.6213 21.1213C19.0587 21.6839 18.2956 22 17.5 22H13.5V14H20.5V19ZM13.5 12V9H20.5C21.0304 9 21.5391 9.21071 21.9142 9.58579C22.2893 9.96086 22.5 10.4696 22.5 11V12H13.5Z"
                    fill="#4FA935" />
                </g>
                <defs>
                  <clipPath id="clip0_3750_5120">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Benefícios</p>
            </div>

            <div>
              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path :style="{ fill: n.id === planeSelect ? '#a5e793' : '#4FA935' }"
                  d="M6.89931 4.87982L2.30931 0.289816C2.12195 0.103565 1.8685 -0.000976562 1.60431 -0.000976562C1.34013 -0.000976562 1.08668 0.103565 0.899314 0.289816C0.805586 0.382779 0.731191 0.49338 0.680423 0.615239C0.629654 0.737098 0.603516 0.867804 0.603516 0.999816C0.603516 1.13183 0.629654 1.26253 0.680423 1.38439C0.731191 1.50625 0.805586 1.61685 0.899314 1.70982L5.49931 6.28982C5.59304 6.38278 5.66744 6.49338 5.71821 6.61524C5.76897 6.7371 5.79511 6.86781 5.79511 6.99982C5.79511 7.13183 5.76897 7.26253 5.71821 7.38439C5.66744 7.50625 5.59304 7.61685 5.49931 7.70982L0.899314 12.2898C0.71101 12.4768 0.604696 12.7309 0.603758 12.9963C0.60282 13.2616 0.707337 13.5165 0.894315 13.7048C1.08129 13.8931 1.33541 13.9994 1.60078 14.0004C1.86614 14.0013 2.12101 13.8968 2.30931 13.7098L6.89931 9.11982C7.46112 8.55732 7.77668 7.79482 7.77668 6.99982C7.77668 6.20481 7.46112 5.44232 6.89931 4.87982Z"
                  fill="#4FA935" />
              </svg>
            </div>
          </div>

          <div :class="['divider', { 'divider-activated': n.id === planeSelect }]"></div>
          <div style="text-align: center">
            <span style="font-size: 14px;" v-if="n.type == 'ano'">em até 4x sem juros</span>
          </div>
          <div class="price-box">
            <span style="font-size: 14px;padding-right: 10px;text-decoration: line-through;">De: R${{
              convertToCents(n.mktPrice) }}</span>
            <div class="price-text-small">R$</div>
            <div class="price-text">{{ convertToCents(n.price) }}</div>
            <div class="price-text-small">/{{ n.type }}</div>
          </div>

          <button v-if="n.id !== planeSelect" @click="selectPlane(n.id)" class="card-choose-btn">
            Escolher Plano {{ n.Title }}
          </button>

          <div class="btn-footer" v-if="n.id === planeSelect">
            <button @click="selectRemovePlane()" class="card-choose-btn-check">
              <svg width="24" height="18" class="" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.39496 19C7.65692 19.0003 6.94912 18.7034 6.42769 18.1746L0.479982 12.156C-0.159994 11.5079 -0.159994 10.4574 0.479982 9.80933C1.12016 9.16147 2.1579 9.16147 2.79808 9.80933L8.39496 15.4751L23.2019 0.48589C23.8421 -0.161963 24.8798 -0.161963 25.52 0.48589C26.16 1.13395 26.16 2.18445 25.52 2.83252L10.3622 18.1746C9.84079 18.7034 9.13299 19.0003 8.39496 19Z"
                  fill="#336D22" />
              </svg>
            </button>
            <button @click="handleClick" class="card-choose-btn-selected">
              Avançar
              <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.16667 9.82642L15.5 9.82642L11.1133 14.2131C10.9884 14.337 10.8892 14.4845 10.8215 14.647C10.7538 14.8095 10.7189 14.9837 10.7189 15.1597C10.7189 15.3358 10.7538 15.51 10.8215 15.6725C10.8892 15.835 10.9884 15.9825 11.1133 16.1064C11.3631 16.3548 11.7011 16.4941 12.0533 16.4941C12.4056 16.4941 12.7435 16.3548 12.9933 16.1064L18.7133 10.3731C19.2141 9.87528 19.497 9.19919 19.5 8.49308C19.4935 7.79161 19.2109 7.12095 18.7133 6.62642L12.9933 0.893082C12.869 0.769639 12.7216 0.671893 12.5595 0.60542C12.3974 0.538948 12.2238 0.505055 12.0486 0.505674C11.8734 0.506293 11.7001 0.541414 11.5385 0.609029C11.3768 0.676645 11.2301 0.775432 11.1067 0.89975C10.9832 1.02407 10.8855 1.17148 10.819 1.33358C10.7525 1.49567 10.7186 1.66927 10.7193 1.84446C10.7199 2.01966 10.755 2.19301 10.8226 2.35463C10.8902 2.51625 10.989 2.66297 11.1133 2.78642L15.5 7.15975L2.16667 7.15975C1.81304 7.15975 1.4739 7.30022 1.22386 7.55027C0.973809 7.80032 0.833333 8.13946 0.833333 8.49308C0.833333 8.8467 0.973809 9.18584 1.22386 9.43589C1.4739 9.68594 1.81304 9.82642 2.16667 9.82642Z"
                  fill="#4FA935" />
              </svg>
            </button>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <Drawer drag-color="#f6f2fe" :max-height="900" ref="myBottomSheet">
      <template #header>
        <div class="drawer-title">Benefícios plano {{ drawerList.title }}</div>
      </template>
      <div class="drawer-box">
        <ul>
          <li class="card-list" v-for="benefit in drawerList.benefits" :key="benefit.name">
            <b-row style="align-items: center;margin-bottom: 5px;">
              <div v-if="benefit.checked === true">
                <svg v-if="benefit.icon === 'check'" width="24" height="15" class="mr-2" viewBox="0 0 24 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M22.3186 0.431008L8.49963 14.249C8.40672 14.3423 8.29631 14.4163 8.17473 14.4668C8.05315 14.5173 7.92279 14.5433 7.79113 14.5433C7.65948 14.5433 7.52912 14.5173 7.40754 14.4668C7.28596 14.4163 7.17554 14.3423 7.08263 14.249L1.73863 8.90001C1.64572 8.80673 1.53531 8.73272 1.41373 8.68222C1.29214 8.63171 1.16179 8.60572 1.03013 8.60572C0.898479 8.60572 0.768121 8.63171 0.646539 8.68222C0.524956 8.73272 0.414542 8.80673 0.321633 8.90001C0.228356 8.99292 0.154344 9.10333 0.103842 9.22491C0.0533404 9.3465 0.0273437 9.47685 0.0273438 9.60851C0.0273437 9.74016 0.0533404 9.87052 0.103842 9.9921C0.154344 10.1137 0.228356 10.2241 0.321633 10.317L5.66763 15.662C6.23158 16.2249 6.99583 16.5411 7.79263 16.5411C8.58944 16.5411 9.35369 16.2249 9.91763 15.662L23.7356 1.84701C23.8288 1.75412 23.9026 1.64377 23.9531 1.52228C24.0035 1.40079 24.0294 1.27054 24.0294 1.13901C24.0294 1.00747 24.0035 0.877231 23.9531 0.755741C23.9026 0.63425 23.8288 0.5239 23.7356 0.431008C23.6427 0.337732 23.5323 0.263719 23.4107 0.213217C23.2891 0.162716 23.1588 0.136719 23.0271 0.136719C22.8955 0.136719 22.7651 0.162716 22.6435 0.213217C22.522 0.263719 22.4115 0.337732 22.3186 0.431008Z"
                    fill="#4FA935" />
                </svg>

                <svg v-if="benefit.icon === 'gift'" width="25" height="24" viewBox="0 0 25 24" class="mr-2" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20.5 7H18.762C19.3274 6.5016 19.7762 5.88488 20.0766 5.1936C20.3769 4.50232 20.5215 3.75341 20.5 3C20.5 2.73478 20.3946 2.48043 20.2071 2.29289C20.0196 2.10536 19.7652 2 19.5 2C19.2348 2 18.9804 2.10536 18.7929 2.29289C18.6054 2.48043 18.5 2.73478 18.5 3C18.5 5.622 16.129 6.53 14.326 6.841C14.9915 5.66105 15.3921 4.35037 15.5 3C15.5 2.20435 15.1839 1.44129 14.6213 0.87868C14.0587 0.31607 13.2956 0 12.5 0C11.7044 0 10.9413 0.31607 10.3787 0.87868C9.81607 1.44129 9.5 2.20435 9.5 3C9.60791 4.35037 10.0085 5.66105 10.674 6.841C8.871 6.53 6.5 5.622 6.5 3C6.5 2.73478 6.39464 2.48043 6.20711 2.29289C6.01957 2.10536 5.76522 2 5.5 2C5.23478 2 4.98043 2.10536 4.79289 2.29289C4.60536 2.48043 4.5 2.73478 4.5 3C4.47851 3.75341 4.62307 4.50232 4.92344 5.1936C5.2238 5.88488 5.6726 6.5016 6.238 7H4.5C3.43913 7 2.42172 7.42143 1.67157 8.17157C0.921427 8.92172 0.5 9.93913 0.5 11L0.5 12C0.5 12.5304 0.710714 13.0391 1.08579 13.4142C1.46086 13.7893 1.96957 14 2.5 14V19C2.50159 20.3256 3.02888 21.5964 3.96622 22.5338C4.90356 23.4711 6.17441 23.9984 7.5 24H17.5C18.8256 23.9984 20.0964 23.4711 21.0338 22.5338C21.9711 21.5964 22.4984 20.3256 22.5 19V14C23.0304 14 23.5391 13.7893 23.9142 13.4142C24.2893 13.0391 24.5 12.5304 24.5 12V11C24.5 9.93913 24.0786 8.92172 23.3284 8.17157C22.5783 7.42143 21.5609 7 20.5 7ZM12.5 2C12.7652 2 13.0196 2.10536 13.2071 2.29289C13.3946 2.48043 13.5 2.73478 13.5 3C13.3793 4.06283 13.0387 5.08891 12.5 6.013C11.9613 5.08891 11.6207 4.06283 11.5 3C11.5 2.73478 11.6054 2.48043 11.7929 2.29289C11.9804 2.10536 12.2348 2 12.5 2ZM2.5 11C2.5 10.4696 2.71071 9.96086 3.08579 9.58579C3.46086 9.21071 3.96957 9 4.5 9H11.5V12H2.5V11ZM4.5 19V14H11.5V22H7.5C6.70435 22 5.94129 21.6839 5.37868 21.1213C4.81607 20.5587 4.5 19.7956 4.5 19ZM20.5 19C20.5 19.7956 20.1839 20.5587 19.6213 21.1213C19.0587 21.6839 18.2956 22 17.5 22H13.5V14H20.5V19ZM13.5 12V9H20.5C21.0304 9 21.5391 9.21071 21.9142 9.58579C22.2893 9.96086 22.5 10.4696 22.5 11V12H13.5Z"
                    fill="#4FA935" />
                </svg>
              </div>
              <div v-if="benefit.checked === false">
                <svg v-if="benefit.icon === 'gift'" width="25" height="22" class="mr-2" viewBox="0 0 25 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3750_5120)">
                    <path :style="{ fill: '#b5b5b5' }"
                      d="M20.5 7H18.762C19.3274 6.5016 19.7762 5.88488 20.0766 5.1936C20.3769 4.50232 20.5215 3.75341 20.5 3C20.5 2.73478 20.3946 2.48043 20.2071 2.29289C20.0196 2.10536 19.7652 2 19.5 2C19.2348 2 18.9804 2.10536 18.7929 2.29289C18.6054 2.48043 18.5 2.73478 18.5 3C18.5 5.622 16.129 6.53 14.326 6.841C14.9915 5.66105 15.3921 4.35037 15.5 3C15.5 2.20435 15.1839 1.44129 14.6213 0.87868C14.0587 0.31607 13.2956 0 12.5 0C11.7044 0 10.9413 0.31607 10.3787 0.87868C9.81607 1.44129 9.5 2.20435 9.5 3C9.60791 4.35037 10.0085 5.66105 10.674 6.841C8.871 6.53 6.5 5.622 6.5 3C6.5 2.73478 6.39464 2.48043 6.20711 2.29289C6.01957 2.10536 5.76522 2 5.5 2C5.23478 2 4.98043 2.10536 4.79289 2.29289C4.60536 2.48043 4.5 2.73478 4.5 3C4.47851 3.75341 4.62307 4.50232 4.92344 5.1936C5.2238 5.88488 5.6726 6.5016 6.238 7H4.5C3.43913 7 2.42172 7.42143 1.67157 8.17157C0.921427 8.92172 0.5 9.93913 0.5 11L0.5 12C0.5 12.5304 0.710714 13.0391 1.08579 13.4142C1.46086 13.7893 1.96957 14 2.5 14V19C2.50159 20.3256 3.02888 21.5964 3.96622 22.5338C4.90356 23.4711 6.17441 23.9984 7.5 24H17.5C18.8256 23.9984 20.0964 23.4711 21.0338 22.5338C21.9711 21.5964 22.4984 20.3256 22.5 19V14C23.0304 14 23.5391 13.7893 23.9142 13.4142C24.2893 13.0391 24.5 12.5304 24.5 12V11C24.5 9.93913 24.0786 8.92172 23.3284 8.17157C22.5783 7.42143 21.5609 7 20.5 7ZM12.5 2C12.7652 2 13.0196 2.10536 13.2071 2.29289C13.3946 2.48043 13.5 2.73478 13.5 3C13.3793 4.06283 13.0387 5.08891 12.5 6.013C11.9613 5.08891 11.6207 4.06283 11.5 3C11.5 2.73478 11.6054 2.48043 11.7929 2.29289C11.9804 2.10536 12.2348 2 12.5 2ZM2.5 11C2.5 10.4696 2.71071 9.96086 3.08579 9.58579C3.46086 9.21071 3.96957 9 4.5 9H11.5V12H2.5V11ZM4.5 19V14H11.5V22H7.5C6.70435 22 5.94129 21.6839 5.37868 21.1213C4.81607 20.5587 4.5 19.7956 4.5 19ZM20.5 19C20.5 19.7956 20.1839 20.5587 19.6213 21.1213C19.0587 21.6839 18.2956 22 17.5 22H13.5V14H20.5V19ZM13.5 12V9H20.5C21.0304 9 21.5391 9.21071 21.9142 9.58579C22.2893 9.96086 22.5 10.4696 22.5 11V12H13.5Z"
                      fill="#4FA935" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3750_5120">
                      <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                <svg v-if="benefit.icon === 'check'" width="18" height="18" class="mr-2" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path :style="{ fill: '#b01111' }"
                      d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z">
                    </path>
                  </g>
                </svg>
              </div>

              <span :class="benefit.checked === true ? 'line-list' : 'line-through-list'">
                {{ benefit.name }}
              </span>
            </b-row>
          </li>
        </ul>
      </div>

      <button @click="close" class="button-drawer">Entendi</button>
    </Drawer>
  </b-container>
</template>
<script>
import * as D from "@/duck";
import { Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import lottie from "lottie-web";
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";
import * as A from "@/assets";
import config from "../../config";
export default {
  components: {
    Swiper,
    SwiperSlide,
    Drawer: VueBottomSheetVue2,
  },
  props: {
    clickHandler: {
      type: Function,
      required: true,
    },
    previousStep: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      config,
      PRICE_OPTIONS_YEARLY: null,
      // PRICE_OPTIONS_WEEK: D.constants.PRICE_OPTIONS_WEEK,
      PRICE_OPTIONS_MONTHLY: null,
      selected: config.ID_TIPO_ASSINATURA_MENSAL,
      planeSelect: null,
      splashArt: A.Spark,
      mySwiper: null,
      hasChange: false,
      isVisible: false,
      drawerList: [],
      plans: [],
      wishPlan: null,
    };
  },
  created() {
    this.initializeFields();
  },
  mounted() {

    const content = this.$el.querySelector(".bottom-sheet__content");
    if (content) {
      content.style.backgroundColor = "#f6f2fe";
    }
    (this.isVisible = true),
      (this.animation = lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: this.splashArt,
      }));

    setTimeout(() => {
      window.scroll({ top: 200, left: 0, behavior: "smooth" });
    }, 1500);
  },

  computed: {
    priceOptions() {
      switch (this.selected) {
        case config.ID_TIPO_ASSINATURA_ANUAL:
          return this.PRICE_OPTIONS_YEARLY;
        // case config.ID_TIPO_ASSINATURA_SEMESTRAL:
        //   return this.PRICE_OPTIONS_WEEK;
        case config.ID_TIPO_ASSINATURA_MENSAL:
          return this.PRICE_OPTIONS_MONTHLY;
        default:
          return this.PRICE_OPTIONS_YEARLY;
      }
    },
  },
  methods: {
    handleClick() {
      this.emitPricingData();
      this.clickHandler();
    },
    initializeFields() {
      this.plans = this.data.plans;

      this.PRICE_OPTIONS_YEARLY = D.constants.PRICE_OPTIONS_YEARLY.map((plan, index) => ({
        ...plan,
        id: this.plans[index].id,
        price: this.plans[index].valorAnualComAssistencia24h,
        mktPrice: this.plans[index].valorAnualComAssistencia24hMKT,
        priceAcessory: this.plans[index].valorAcessorioAnual
      }));

      this.PRICE_OPTIONS_MONTHLY = D.constants.PRICE_OPTIONS_MONTHLY.map((plan, index) => ({
        ...plan,
        id: this.plans[index].id,
        price: this.plans[index].valorMensalComAssistencia24h,
        mktPrice: this.plans[index].valorMensalComAssistencia24hMKT,
        priceAcessory: this.plans[index].valorAcessorioMensal
      }));

      this.selected = this.data.planeType || config.ID_TIPO_ASSINATURA_MENSAL;
      this.planeSelect = this.data.planeValue?.id || config.ID_PLANO_PERFORMANCE;
    },
    openzada(items, title) {
      this.drawerList = { benefits: items, title };

      setTimeout(() => {
        this.$refs.myBottomSheet.open();
      }, 300);
    },

    close() {
      this.$refs.myBottomSheet.close();

      setTimeout(() => {
        this.drawerList = [];
      }, 300);
    },
    onSwiper(swiper) {
      this.mySwiper = swiper;
    },
    checkAndPlay() {
      this.animation.play();
    },
    emitPricingData() {
      var plane = this.priceOptions.find((e) => e.id === this.planeSelect)
      this.$emit("pricingData", {
        planeType: this.selected,
        planeValue: plane,
        valueAcessory: plane.priceAcessory,
        plans: this.plans,
      });
    },
    onSlideChange() { },
    selectOption(option) {
      this.selected = option;
    },
    selectPlane(id) {
      this.planeSelect = id;
    },
    selectRemovePlane() {
      this.planeSelect = null;
    },
    prevSlide() {
      if (this.mySwiper) {
        this.mySwiper.slidePrev();
      }
      this.hasChange = false;
    },
    nextSlide() {
      if (this.mySwiper) {
        this.mySwiper.slideNext();
      }
      this.hasChange = false;
    },
    convertToCents(price) {
      return price.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
    },

  },
  watch: {
    planeSelect() {
      if (this.planeSelect === config.ID_PLANO_PERFORMANCE && this.selected == config.ID_TIPO_ASSINATURA_ANUAL) {
        this.animation.play();
      } else {
        this.animation.stop();
      }
    },

  },
};
</script>
<style scoped>
.continue-btn {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #4FA935;
  border-radius: 8px;
  background: #4FA935;
  color: #fff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  text-wrap: nowrap;
}

.ghost {
  font-size: 12px;
  text-align: center;
  padding: 10px 15px 10px 15px;
  border: 1px solid transparent;
  border-radius: 8px;
  background: #FFFFFF;
  color: #78849E;
}

.col,
.col-2,
.col-6 {
  padding: 0 10px;
}

.title-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
}

.button-container {
  display: grid;
  position: relative;
}

.chip-top {
  position: absolute;
  bottom: -10px;
  /* Ajuste conforme necessário para mover para fora do botão */
  left: 50%;
  transform: translateX(-50%);
  background-color: #e9e9e9;
  color: #5b6fa2;
  width: 62px;
  height: 23px;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 12px;
  padding: 4px, 8px, 4px, 8px;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to

/* Início e fim da animação */
  {
  transform: translateX(-100%);
}

.chip-top-enabled {
  position: absolute;
  bottom: -10px;
  /* Ajuste conforme necessário para mover para fora do botão */
  left: 50%;
  transform: translateX(-50%);
  background-color: #78849e;
  color: #e9e9e9;
  width: 62px;
  height: 23px;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 12px;
  padding: 4px, 8px, 4px, 8px;
}

.chip {
  border: solid 1px #4fa935;
  color: #4caf50;
  border-radius: 10px;
  gap: 10px;
  margin-left: 5px;
  margin-top: 8px;
  width: 62px;
  height: 23px;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
}

.chip-selected {
  border: solid 1px white;
  color: white;
  border-radius: 10px;
  gap: 10px;
  margin-left: 5px;
  margin-top: 8px;
  width: 62px;
  height: 23px;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 66px;
  background-color: #78849e80;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}

.swiper-button-prev {
  left: 0px;
  clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
}

.swiper-button-next {
  right: 0px;
  clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
}

.title-link {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
  text-decoration: underline;
}

.drawer-title {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  color: #5b6fa2;
}

.drawer-title-better {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
  color: #5b6fa2;
}

.btn-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-text {
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 42px;
}

.price-text-small {
  align-self: flex-start;
  margin-top: 13px;
  font-weight: bold;
  font-size: 14px;
}

.btn-div {
  align-items: space-between;
  padding: 12px;
}

.line-through {
  text-decoration: line-through;
  color: rgb(156, 156, 156);
}

.line-through-list {
  text-decoration: line-through;
  margin-top: 5px;
  color: rgb(156, 156, 156);
  font-size: 17px;
}

.line-list {
  color: #7788b2;
  font-size: 17px;
}

.line-through-selected {
  text-decoration: line-through;
  color: #91c882;
}

.card-container {
  background-color: #fff;
  color: #425994;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s ease, color 0.5s ease, box-shadow 0.5s ease;
}

.flex-row {
  display: flex;
  color: #4fa935;
}

.flex-row-activated {
  display: flex;
  color: #a5e793;
}

.icon-back {
  color: #425994;
  position: absolute;
  /* Alterado de absolute para fixed para ficar fixo na tela */
  width: 9%;
  top: 30px;
  left: 5;
}

.card-activated {
  background-color: #4fa935;
  color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.divider {
  background-color: #ccc;
  height: 1px;
  margin: 10px 0;
}

.divider-activated {
  background-color: #91c882;
  height: 1px;
  margin: 10px 0;
}

.card-title {
  text-align: center;
  display: flex;
  font-size: 24px;
  justify-content: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.lottie {
  position: absolute;
  z-index: 9;
  top: 50%;
  pointer-events: none;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 500px;
}

.text-header {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0px !important;
  font-weight: bold;
  color: #5db247;
}

.drawer-box-better {
  display: block;
  height: 70px;
}

.drawer-box {
  display: block;
  height: 310px;
}

.card-list {
  list-style-type: none;
  padding-left: 0;
}

.container {
  height: 20%;
}

.card-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.card-list-item::before {
  color: #4caf50;
  margin-right: 10px;
}

.card-benefits {
  justify-content: space-between;
  color: #4caf50;
  font-weight: bold;
  display: flex;
}

.card-benefits::before {
  color: #dfe2df;
  margin-right: 10px;
}

.card-choose-btn {
  background-color: #fff;
  color: #425994;
  border: 1px solid #425994;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  height: 45px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  display: block;
  text-align: center;
  text-decoration: none;
}

.button-drawer {
  background-color: #7c849c;
  color: #ffffff;
  border: 1px solid #7c849c;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
  width: 100px;
  align-self: center;
  height: 45px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  display: block;
  text-align: center;
  text-decoration: none;
}

.card-choose-btn-selected {
  display: flex;
  background-color: #fff;
  color: #4caf50;
  border: 1px solid #4caf50;
  border-radius: 12px;
  font-weight: bold;
  width: 60%;
  height: 48px;
  margin-top: 20px;
  display: block;
  text-align: center;
  text-decoration: none;
}

.card-choose-btn-check {
  background-color: #a6e894;
  color: #4caf50;
  border: 1px solid #4caf50;
  border-radius: 20px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  width: 51px;
  height: 51px;
  margin-top: 20px;
  display: block;
  text-align: center;
  text-decoration: none;
}

.btn-checkd,
.btn-noCheck {
  transition: background-color 0.8s ease, color 0.8s ease, border-color 0.8s ease;
}

.btn-checkd {
  background-color: #5db247;
  color: #fafafa;
  border: solid 1px #5db247;
  border-radius: 12px;
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
  width: 90px;
  height: 50px;
}

.btn-noCheck {
  background-color: #fafafa;
  color: #78849e;
  border: solid 1px #dadada;
  font-size: 12px;
  font-weight: bold;
  border-radius: 12px;
  padding: 8px;
  width: 90px;
  height: 50px;
}

.title-description {
  font-size: 16px;
  margin-top: 10px;
  line-height: 24px;
  color: #919ab0;
}
</style>
